var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { Tooltip } from "react-tippy";
var InputTooltip = /** @class */ (function (_super) {
    __extends(InputTooltip, _super);
    function InputTooltip() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.render = function () {
            return (React.createElement(Tooltip, { position: "left", html: (React.createElement("div", { className: "tooltip-inner" },
                    React.createElement("span", { className: "h3" }, _this.props.title),
                    React.createElement("p", null, _this.props.content))), trigger: "mouseenter", className: "form__tooltip", arrow: true, size: "small", animation: "none" },
                React.createElement("svg", null,
                    React.createElement("use", { xlinkHref: "#icon--info" }))));
        };
        return _this;
    }
    return InputTooltip;
}(React.PureComponent));
export default InputTooltip;
